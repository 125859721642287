import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Structure } from "@lib/simba/_statics"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import getAction from "@utils/get-form-action"
import getSrc from "@utils/get-root"

const Head = styled.div`
  position: relative;
  z-index: 1;
  padding: ${({ theme }) => theme.paddingBox};
  background: white;
  align-items: center;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
`

const Resume = styled.div`
  padding: ${({ theme }) => theme.paddingBox};
`
const Title = styled.h1`
  color: ${({ theme }) => theme.colorTextTitle};
`
const ResumeResume = styled.div``
const ImgContainer = styled.div`
  overflow: hidden;
  height: 66vh;
  position: relative;
`

const SvgMask = styled.svg`
  position: absolute;
  height: 100%;
  width: 350px;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    display: block;
  }
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const Body = styled.div`
  position: relative;
  z-index: 2;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    background: rgba(255, 255, 255, 0.7);
    border: calc(${({ theme }) => theme.paddingBox}*0.66) solid
      ${({ theme }) => theme.colorText};
    width: ${({ theme }) => theme.breakpoints.desktop};
    margin: ${({ theme }) => theme.paddingBox} auto;
    margin-top: -60px;
    padding: ${({ theme }) => theme.paddingBox};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`

const InnerBody = styled.div`
  background: white;
  padding: ${({ theme }) => theme.paddingBox};
  p:last-of-type {
    margin: 0;
  }
`

const FormContainer = styled.div`
  padding: ${({ theme }) => theme.paddingBox};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Input = styled.input`
  border: 0;
  background: transparent;
  padding: ${({ theme }) =>
    `calc(${theme.paddingBox} / 4) calc(${theme.paddingBox} / 2)`};
  margin: ${({ theme }) => `calc(${theme.paddingBox} / 2) 0`};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
  outline: none;
`

const Textarea = styled.textarea`
  padding: ${({ theme }) =>
    `calc(${theme.paddingBox} / 4) calc(${theme.paddingBox} / 2)`};
  border: 1px solid ${({ theme }) => theme.colorBorder};
  margin: ${({ theme }) => `calc(${theme.paddingBox} / 2) 0`};
  height: 100px;
  outline: none;
`

const Submit = styled.input`
  align-self: flex-end;
  padding: ${({ theme }) =>
    `calc(${theme.paddingBox} / 4) calc(${theme.paddingBox} / 1)`};
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.backgroundLight};
  cursor: pointer;
  border: 0;
`

const Article = ({ data }) => (
  <Structure hideFooterArticle={true}>
    <Head>
      <Resume>
        <Title>{data.article.title}</Title>
        <ResumeResume>{data.article.resume}</ResumeResume>
        <FormContainer>
          <Form
            action="https://io.jackandrussells.com/api/sender/send"
            accept-charset="UTF-8"
            enctype="multipart/form-data"
            method="POST"
          >
            <input type="hidden" name="_jessica" />
            <input
              type="hidden"
              name="_token"
              value={process.env.GATSBY_CLIENT_TOKEN}
            />
            <Input type="text" name="nom" placeholder="Nom" />
            <Input type="text" name="tel" placeholder="Téléphone" />
            <Input type="text" name="email" placeholder="Email" />
            <Textarea name="message" placeholder="Message"></Textarea>
            <Submit type="submit" value="Envoyer" />
          </Form>
        </FormContainer>
      </Resume>
      <ImgContainer>
        <SvgMask viewBox="0 0 20 20" preserveAspectRatio="none">
          <path d="M0 0 H 20 L 2 20 H 0 Z" fill="white" />
        </SvgMask>
        <StyledImg
          fluid={data.article.cover.asset.fluid}
          imgStyle={{
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </ImgContainer>
    </Head>
  </Structure>
)

export default Article

export const query = graphql`
  query ContactContent($id: String) {
    article: sanityContenu(id: { eq: $id }) {
      title
      resume
      _rawContent(resolveReferences: { maxDepth: 5 })
      categories {
        title
      }
      seo {
        title
        keywords
        description
      }
      cover {
        asset {
          url
          metadata {
            dimensions {
              height
              width
            }
          }
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
